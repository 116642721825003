import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GetPlayerReportByRId, saveReportAnalitics } from "../../Store/action/reportsAction"
import { SyncLoader } from "react-spinners";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from "html2pdf.js";
import { CLIENT_URL } from "../../Config/keys"



export default function ScoutingReport() {

  let dispatch = useDispatch()
  const { error, successMessage } = useSelector((state) => state.users)
  const { reportData, reportError, reportSuccessMessage } = useSelector((state) => state.report)

  console.log(18, reportData);

  let { id } = useParams()

  const [Ploader, setPloader] = useState(false)
  const [curBrand, setCurBrand] = useState({})
  const [curVideos, setCurvideos] = useState([])

  /* save-report-analytics-function */
  const saveReportAnalytics = (type) => {
    let dataset = {
      adminId: reportData.adminId,
      creatorId: reportData.creatorId,
      reportId: reportData._id,
      name: type
    }
    console.log(36, dataset);
    dispatch(saveReportAnalitics(dataset))
  }

  /**************************************** Download + Print + Share *************************************/


  const downloadPDF = () => {
    saveReportAnalytics("PDF");

    // https://ekoopmans.github.io/html2pdf.js/#worker-api
    const domElement = document.getElementById("root");
    document.body.classList = "scale-down";
    var element = document.getElementById("root");
    var opt = {
      margin: 0,
      filename: "myfile.pdf",
      image: { type: "jpg", quality: 0.99 },
      html2canvas: { scale: 1, useCORS: true, letterRendering: true, dpi: 192 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
  };


  const printPDF = () => {
    saveReportAnalytics("Print");

    const domElement = document.getElementById("root");
    document.body.classList = "scale-down";
    var element = document.getElementById("root");
    var opt = {
      margin: 0,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1, useCORS: true },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },

    };

    // html2pdf().set(opt).from(element).save();

    html2pdf()
      .from(element)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        window.open(pdf.output("bloburl"), "_blank");
      });
  };

  const WhatsAppShare = () => {
    saveReportAnalytics("WhatsApp");

    const url = `${CLIENT_URL}/report/${reportData._id}`;
    let mail = `https://wa.me/?text="${url}"`;
    window.open(mail, "_blank");
  };

  const emailShare = () => {
    saveReportAnalytics("Email");

    const url = `${CLIENT_URL}/report/${reportData._id}`;
    let mail = `mailto:subject="your video from spotojet"& body= "${url}"`;
    window.open(mail, "_blank");
  };



  useEffect(() => {
    if (reportData && Object.keys(reportData).length > 0) {
      setTimeout(() => {
        setPloader(true);
      }, 1000);
    }
  }, [reportData]);

  useEffect(() => {
    if (id && id.length > 0) {
      dispatch(GetPlayerReportByRId({ reportId: id }))
    }
  }, [id])

  useEffect(() => {
    // MyBarnd
    if (reportData && reportData.MyBarnd && Object.keys(reportData.MyBarnd).length > 0 && reportData.MyBarnd.isActive === true) {
      setCurBrand(reportData.MyBarnd)
    }
  }, [reportData])


  useEffect(() => {
    if (reportData && reportData.MyVideoS && reportData.MyVideoS.length > 0) {
      setCurvideos(reportData.MyVideoS)
    }
  }, [reportData])


  // MyVideoS
  function hexToRgb(hex) {
    // Remove the # symbol if it's included
    hex = hex.replace(/^#/, "");

    // Parse the hex values into separate R, G, and B values
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b},0.5)`;
  }


  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = async () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [1440, 991]
    });

    // Adding the fonts.
    doc.setFont('Inter-Regular', 'normal');

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save('document');
      },
    });
  };


  const captureAndDownloadPdf = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const content = document.getElementById('full-screen-content');

    if (!content) {
      return;
    }

    const canvas = await html2canvas(content);
    const imgData = canvas.toDataURL('image/jpeg');

    pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
    pdf.save('full_screen.pdf');
  };



  const dateFormat = (originalDateString) => {
    // const originalDateString = "2023-07-09T12:27:45.336+00:00";
    const dateObj = new Date(originalDateString);

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
    const year = dateObj.getFullYear();

    return `${day}/${month}/${year}`;
  }


  let [cardType, setCardType] = useState([]);
  let [goalScored, setGoalScored] = useState([]);
  let [subType, setSubType] = useState([]);

  useEffect(() => {
    if (reportData.MatchDatas && reportData.MatchDatas.length > 0) {
      let goal, CardType;
      reportData.MatchDatas.map((value, index) => {

        const formattedGoal = value?.assScore && value?.assScore?.GoalScored.map((vasg, index) => {
          return `Goal ${index + 1} (${vasg.time})`;
        })


        const formattedCardInfo = value?.assScore && value?.assScore?.Cards.map((vasc, index) => {
          return `${vasc.cardType.charAt(0).toUpperCase() + vasc.cardType.slice(1)} (${vasc.time})`;
        })

        const formattedSubInfo = value?.assScore && value?.assScore?.Substitutes.map((vass, index) => {
          if (vass.subType == "WasSubstituted") {
            return `In (${vass.time})`
          } else {
            return `Out (${vass.time})`
          }
        })


        setGoalScored(formattedGoal);
        setCardType(formattedCardInfo);
        setSubType(formattedSubInfo);
      })

    }
  }, [reportData]);


  console.log(goalScored);
  console.log(cardType);
  console.log(subType);


  return (
    <>
      {Ploader ?

        <div ref={reportTemplateRef} className="report-body">
          {curBrand && Object.keys(curBrand).length === 0 ?
            <header className="container-fluid">
              <div className="row">
                <div className="col-sm-10">
                  <h1 className="text-start">
                    {reportData && reportData.feedbackId && reportData.feedbackId.length > 0 ? "Feedback Report" : "Scouting Report"}
                  </h1>
                </div>
                <div className="col-sm-2 text-end">
                  <a className="navbar-brand" href="#">
                    <img className="logo" src={`${CLIENT_URL}/images/logo.png`} />
                    <p className="text-end">More Impact. Less Effort</p>
                  </a>
                </div>
              </div>
            </header> : null}

          {/* <!-- white labelled header --> */}
          {curBrand && Object.keys(curBrand).length > 0 ?
            <div>
              <header className="container-fluid white-labelled">
                <div className="row">
                  <div className="col-sm-10">
                    <a className="navbar-brand" href="#">
                      <img
                        className="logo"
                        src={curBrand && curBrand.logo ? curBrand.logo : "https://placehold.co/191x84?text=LOGO"}
                      />
                    </a>
                  </div>
                  <div className="col-sm-2 text-end report-header-text">
                    <p className="text-center text-md-end">
                      Placeholder
                      <br />
                      {curBrand && curBrand.headerText ? curBrand.headerText : ""}
                    </p>
                  </div>
                </div>
              </header>
              <header className="container-fluid header-bottom">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="text-start" style={{ color: curBrand && curBrand.titleColor ? curBrand.titleColor : "blue" }}>
                      {reportData && reportData.feedbackId && reportData.feedbackId.length > 0 ? "Feedback Report" : "Scouting Report"}
                    </h1>
                  </div>
                </div>
              </header>
            </div> : null}


          <section className="container-fluid report-content">
            <div className="row player-section mb-4">

              {reportData?.playerId?.name ? (
                <div className="col-md-3">
                  Player Name: <strong>{reportData?.playerId?.name}</strong>
                </div>
              ) : null}

              {reportData?.playerId?.club && reportData?.playerId?.team ? (
                <div className="col-md-3">
                  Club / Team: <strong>{`${reportData?.playerId?.club}/ ${reportData?.playerId?.team}`}</strong>
                </div>
              ) : null}

              {reportData?.playerId?.position ? (
                <div className="col-md-3">
                  Position: <strong>{reportData?.playerId?.position}</strong>
                </div>
              ) : null}

              {reportData?.playerId?.coach ? (
                <div className="col-md-3">
                  Prepared By: <strong>{reportData?.playerId?.coach}</strong>
                </div>
              ) : null}

              {reportData?.reportPeriod? (
                <div className="col-md-3">
                Report period: <strong>{reportData?.reportPeriod}</strong>
              </div>
              ) : null}

              
            </div>


            <div className="row match-section html2pdf__page-break">


              {reportData.MatchDatas && reportData.MatchDatas.length > 0 ? (<h2 className="section-heading">Match Details</h2>) : null}

              {reportData.MatchDatas && reportData.MatchDatas.length > 0 ?
                reportData.MatchDatas.map((value, index) => {

                  return (
                    <div className="row match-section mb-4" key={index}>

                      {value.mDate && value.mTime ? (
                        <div className="col-md-3">
                          Date & Time: <strong>{`${value.mDate} ${value.mTime}`}</strong>
                        </div>
                      ) : null}

                      {value.location ? (<div className="col-md-3">
                        Location: <strong>{value.location}</strong>
                      </div>) : null}

                      {value.event ? (<div className="col-md-3">
                        Event: <strong>{value.event}</strong>
                      </div>) : null}

                      {value.weather ? (<div className="col-md-3">
                        Weather: <strong>{value.weather}</strong>
                      </div>) : null}

                      {value.htFormations ? (<div className="col-md-3">
                        Home Team Formation: <strong>{value.htFormations}</strong>
                      </div>) : null}

                      {value.atFormations ? (<div className="col-md-3">
                        Away Team  Formation: <strong>{value.atFormations}</strong>
                      </div>) : null}

                      {value.format ? (<div className="col-md-3">
                        Format: <strong>{value.format}</strong>
                      </div>) : null}

                      {value.mhh && value.mha ? (<div className="col-md-3">
                        Match Score Half-Time: <strong>{`${value.mhh} - ${value.mha}`}</strong>
                      </div>) : null}

                      {value.mfh && value.mfa ? (<div className="col-md-3">
                        Match Score Full-Time: <strong>{`${value.mfh} - ${value.mfa}`}</strong>
                      </div>) : null}

                      {value.venue ? (<div className="col-md-3">
                        Venue: <strong>{value.venue}</strong>
                      </div>) : null}

                      {value.ageGroup ? (<div className="col-md-3">
                        Age Group: <strong>{value.ageGroup}</strong>
                      </div>) : null}

                      {value.level ? (<div className="col-md-3">
                        Compitition LVL: <strong>{value.level}</strong>
                      </div>) : null}

                      {value.viewing ? (<div className="col-md-3">
                        Viewing: <strong>{value.viewing}</strong>
                      </div>) : null}


                      {
                        goalScored && goalScored.length > 0 ? (
                          <div className="col-md-3" key={index}>
                            Goal Scored: <strong>{goalScored.join(" , ")}</strong>
                          </div>
                        ) : null
                      }

                      {
                        cardType && cardType.length > 0 ? (
                          <div className="col-md-3" key={index}>
                            Cards: <strong>{cardType.join(" , ")}</strong>
                          </div>
                        ) : null
                      }

                      {
                        subType && subType.length > 0 ? (
                          <div className="col-md-3" key={index}>
                            Substitute: <strong>{subType.join(" , ")}</strong>
                          </div>
                        ) : null
                      }


                      <>

                        {
                          value.matchMetaData
                            ? value.matchMetaData.map((smmd, index) => {
                              if (smmd.metaKey && smmd.metaValue) {
                                return (
                                  <div className="col-md-3" key={index}>
                                    {smmd.metaKey}: <strong>{smmd.metaValue}</strong>
                                  </div>
                                );
                              }
                              return null;
                            })
                            : null
                        }

                      </>

                    </div>
                  );
                }) : null
              }

            </div>


            <div className="row graph-section mb-4 html2pdf__page-break">
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <h2 className="section-heading green">Overall Score</h2>
                    {/* <div className="overall-score">{reportData.OverallScore}</div> */}
                    <div className="overall-score-one">
                      <div className="number-overall-score-two">
                        <span>{reportData.OverallScore}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <h3 className="section-heading">General comment</h3>
                    <p>{reportData.OverallComments}</p>
                  </div>
                </div>


                <div>
                  {reportData.parameter && reportData.parameter.length > 0 ? (
                    reportData.parameter.map((allValue, index) => {
                      return allValue && allValue.length > 0 ? (
                        allValue.map((value, index) => {
                          // Define the unique key for each mapped element
                          const uniqueKey = `${index}_${value.category}_${value.parameter}`;

                          let allParameters = [];
                          if (value.parameters && value.parameters.length > 0) {
                            allParameters = [...value.parameters];
                          }

                          return (
                            <div className="row bars" key={uniqueKey}>
                              <h3 className="category">{value.category}</h3>
                              {allParameters && allParameters.length > 0 ? (
                                allParameters.map((item, indexs) => {
                                  // Define a unique key for each parameter item
                                  const parameterKey = `${indexs}_${item.parameter}`;

                                  let Scores = '';
                                  if (item.Score) {
                                    Scores = `${(item.Score * 100) / 10}%`;
                                  }

                                  return (
                                    <div className="row bars mt-2" key={parameterKey}>
                                      <div className="col-sm-1">
                                        <div
                                          className="score"
                                          style={{
                                            backgroundColor:
                                              curBrand && curBrand.buttonColor
                                                ? curBrand.buttonColor
                                                : '',
                                          }}
                                        >
                                          {item.Score}
                                        </div>
                                      </div>

                                      <div className="col-sm-8">
                                        <div className="progress">
                                          <div
                                            className="progress-bar"
                                            style={{
                                              backgroundColor:
                                                curBrand && curBrand.progressBarColor
                                                  ? curBrand.progressBarColor
                                                  : '',
                                              width: Scores,
                                            }}
                                            role="progressbar"
                                            aria-valuenow="10"
                                            aria-valuemin="1"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                      </div>

                                      <div className="col-sm-3">
                                        <div className="parameter">
                                          <i
                                            className="fa-solid fa-circle"
                                            style={{
                                              color:
                                                curBrand && curBrand.progressBarColor
                                                  ? curBrand.progressBarColor
                                                  : '',
                                            }}
                                          ></i>{' '}
                                          {item.parameter}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : null}
                            </div>
                          );
                        })
                      ) : null;
                    })
                  ) : null}
                </div>

              </div>

            </div>



            <div className="row analysis-section mb-4 html2pdf__page-break">
              {reportData.parameter && reportData.parameter.length > 0 ? (
                reportData.parameter.map((allValue, index) => {
                  return allValue && allValue.length > 0 ? (
                    allValue.map((value, valueIndex) => {
                      const allParameters = value.parameters || [];

                      return (
                        <div className="container" key={`${index}_${valueIndex}`}>
                          <div className="col-md-12">
                            <h2 className="section-heading">{value.category}</h2>
                          </div>
                          {allParameters.length > 0 ? (
                            allParameters.map((item, itemIndex) => {
                              const Scores = item.Score ? `${(item.Score * 100) / 10}%` : '';

                              return (
                                <div className="row category mb-2" key={`${index}_${valueIndex}_${itemIndex}`}>
                                  <div className="col-sm-3 parameter">
                                    <h3 className="mt-3">{item.parameter}</h3>
                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        style={{
                                          backgroundColor: curBrand && curBrand.progressBarColor ? curBrand.progressBarColor : "",
                                          width: Scores,
                                        }}
                                        role="progressbar"
                                        aria-valuenow="10"
                                        aria-valuemin="1"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="col-sm-8 comment">
                                    <p className="mt-3">
                                      {item.selectComments === "defaultComments" ? item.Comments : item.magicComments}
                                    </p>
                                  </div>
                                  <div className="col-sm-1 text-end">
                                    <div
                                      className="score"
                                      style={{ backgroundColor: curBrand && curBrand.buttonColor ? curBrand.buttonColor : "" }}
                                    >
                                      {item.Score}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : null}
                        </div>
                      );
                    })
                  ) : null;
                })
              ) : null}
            </div>




            {reportData && reportData.feedbackId && reportData.feedbackId.length > 0 ?
              <div className="row videos-section mb-4 html2pdf__page-break">
                <h2 className="section-heading mt-4">Uploaded Videos</h2>
                <div className="container">
                  {curVideos && curVideos.length > 0 ?
                    curVideos.map((value, index) => {
                      let curDate = dateFormat(value.createdAt)
                      return (
                        <div className="row category mb-4">
                          <div className="col-md-2 thumbnail">

                            <video controls controlsList="nodownload" className="video66" src={value.video} type="video/mp4"></video>

                          </div>

                          <div className="col-md-3 name p-2">
                            <h3>{value && value.title ? value.title : ""}</h3>
                          </div>

                          <div className="col-md-5 comment p-2">
                            {value && value.comment ? value.comment : ""}
                          </div>

                          <div className="col-md-2 date p-2">{curDate}</div>
                        </div>
                      )
                    })


                    : null}
                </div>
              </div> : null}

          </section>

          <section className="container print-options" data-html2canvas-ignore="true">
            <div className="row">
              <div className="col-md-12 text-center dropdown">

                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={downloadPDF}
                  style={{ backgroundColor: curBrand && curBrand.buttonColor ? curBrand.buttonColor : "" }}
                >
                  <i className="fa-solid fa-download"></i> Download
                </button>

                <button type="button" className="btn btn-dark" onClick={printPDF}
                  style={{ backgroundColor: curBrand && curBrand.buttonColor ? curBrand.buttonColor : "" }}
                >
                  <i className="fa-solid fa-print"></i> Print
                </button>






                {/* <button type="button" className="btn btn-dark"
                  style={{ backgroundColor: curBrand && curBrand.buttonColor ? curBrand.buttonColor : "" }}
                  onClick={() => {
                    setOpenBtn((curState) => !curState);
                  }}
                >
                  <i className="fa-solid fa-share-square"></i> Share
                </button> */}


                {/* <div className="dropdown"> */}
                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                  style={{ backgroundColor: curBrand && curBrand.buttonColor ? curBrand.buttonColor : "" }}
                >
                  Share
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" onClick={WhatsAppShare}>WhatsApp</a></li>
                  <li><a class="dropdown-item" onClick={emailShare}>Email</a></li>
                </ul>
                {/* </div> */}


              </div>
            </div>
          </section>

          {curBrand && Object.keys(curBrand).length === 0 ?
            <footer className="mt-auto mt-5">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <a className="navbar-brand" href="#">
                    <img className="logo" src={`${CLIENT_URL}/images/logo.png`} />
                  </a>
                  <p className="mt-3">
                    &copy;2023 All Rights Reserved. SportoJet Inc.
                    <br />
                    support@sportojet.com
                  </p>
                </div>
              </div>
            </footer> : null}

          {/* <!-- white labelled footer --> */}
          {curBrand && Object.keys(curBrand).length > 0 ?
            <div>
              <footer style={{ backgroundColor: curBrand && curBrand.footerBgColor ? hexToRgb(curBrand.footerBgColor) : "" }} className="mt-auto white-labelled mt-5">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <a className="navbar-brand" href="#">
                      <img
                        className="logo"
                        src={curBrand && curBrand.logo ? curBrand.logo : "https://placehold.co/191x84?text=LOGO"}
                      />
                    </a>
                  </div>
                </div>
              </footer>

              <footer className="mt-auto footer-bottom mt-5">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <p className="mt-3">Powered by SportoJet</p>
                  </div>
                </div>
              </footer>
            </div>
            : null}

        </div>
        :
        <div className="page-loader">
          <SyncLoader color="#36d7b7" />
        </div>

      }
    </>

  );
}
