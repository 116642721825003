import React from 'react';
import logo from "./sportoLogo.svg";
import { useNavigate } from 'react-router-dom';

export const PrivacyPolicy = () => {

    const navigate = useNavigate();

    const privacyPolicyStyle = {
        div: {
            fontFamily: 'Arial, sans-serif',
            color: '#333',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            backgroundColor: '#fff',
            padding: '5% 10%',
        },
        heading: {
            color: "black",
            fontSize: "2rem",
            fontWeight: "bold",
            margin: "2% 0% 1% 0%",
        },

        subHeading: {
            color: "black",
            fontSize: "1.5rem",
            margin: "2% 0% 1% 0%",
        },
        strong: {
            color: "black",
            margin: "2% 0% 1% 0%",
        },

        imgHomeContainer: {
            display: "flex",
            justifyContent: "space-between",
        },

        imgDiv: {
            padding: "0%",
            margin: "0% 0% 1% 0%",
        },
        img: {
            width: "100%",
            cursor: "pointer",
            padding: "0px",
            margin: "0px",
        },
        ul: {
            listStyleType: "disc",
            marginLeft: "20px",
        },
        li: {
            marginBottom: "5px",
        }


    };

    return (
        <>


            <div className='privacyPolicy_cont' style={privacyPolicyStyle.div}>

                <div style={privacyPolicyStyle.imgHomeContainer}>

                    <div style={privacyPolicyStyle.imgDiv} onClick={() => { navigate("/") }}>
                        <img src={logo} alt="logo" style={privacyPolicyStyle.img} />
                    </div>

                    <div>
                        <h1 style={{ marginLeft: "1vw", cursor: "pointer", textDecoration: "underline" }} onClick={() => { navigate("/") }}>Home</h1>
                    </div>

                </div>


                <h1 style={privacyPolicyStyle.heading}>Privacy Policy</h1>

                <h2 style={privacyPolicyStyle.subHeading}>Introduction</h2>
                <p>
                    If you choose to use our App, then you agree to the collection and use of information in relation to this policy. The information that we collect is used for providing and improving the App. We will not use or share your information with anyone except as described in this Privacy Policy.
                    It's very important that you understand what information this app collects about you, for what purposes and what the system permissions you give are used for, so by agreeing to this Privacy Policy you are agreeing to the following key points below.
                </p>

                <h2 style={privacyPolicyStyle.subHeading}>Permissions Used</h2>
                <p>
                    <strong style={privacyPolicyStyle.strong}>a) Storage Access -</strong>  This app uses your storage access to record, save, and load all player assessments, feedbacks and related data.<br />
                    <strong style={privacyPolicyStyle.strong}>b) Draw On Screen -</strong> This permission enables the App to draw overlay recording controls for ease of use during assessments.<br />
                    <strong style={privacyPolicyStyle.strong}>c) Microphone - </strong>This permission allows the App to record audio for comprehensive performance evaluations and ease of making notes for you.<br />
                    <strong style={privacyPolicyStyle.strong}>d) Camera -</strong> This permission is used if you wish to record visual aspects of the players’ performance.<br />
                </p>

                <h2 style={privacyPolicyStyle.subHeading}>Information Collection and Use</h2>
                <p>
                    a. Information we get from your use of the application is primarily non-personally-identifying information of the sort that services like Google Analytics typically make available, such as the operating system name and version, device manufacturer, device model and identifier etc. For a better experience while using our Service, we may require you to provide us with certain personally identifiable information (like your account log in to our App and following subscription data).<br />
                    b. We may use this data to analyze how the Application is being used or to better support you. Other non-identifying information that we might have access to includes how you use the application, your approximate location, etc.<br />
                    c. All data is stored on Azure servers, employing robust encryption to safeguard your information.<br />
                </p>

                <h1 style={privacyPolicyStyle.heading}>Third Party Partners</h1>
                <h2 style={privacyPolicyStyle.subHeading}>Cookies</h2>
                <p>
                    Cookies are files with small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device’s internal memory. <br />
                    This Service does not use these cookies explicitly. However, the app may use third party code and libraries that use cookies to collect information and improve their service. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                </p>

                <h2 style={privacyPolicyStyle.subHeading}>Service Providers</h2>
                <p>
                    We may employ third-party companies and individuals due to the following reasons:
                    <ul style={privacyPolicyStyle.ul}>
                        <li style={privacyPolicyStyle.li}>To facilitate our Service;</li>
                        <li style={privacyPolicyStyle.li}>To provide the Service on our behalf;</li>
                        <li style={privacyPolicyStyle.li}>To perform Service-related services; or</li>
                        <li style={privacyPolicyStyle.li}>To assist us in analysing how our Service is used.</li>
                    </ul>
                </p>

                <h2 style={privacyPolicyStyle.subHeading}>List of Third-Party companies</h2>
                <p>
                    The app utilizes services, which may include, but are not limited to, the following third-party companies that have the capability to gather information used to identify you, such as:
                    <ul style={privacyPolicyStyle.ul}>
                        <li style={privacyPolicyStyle.li}>Google Play Services</li>
                        <li style={privacyPolicyStyle.li}>Firebase Analytics</li>
                        <li style={privacyPolicyStyle.li}>Azure Services</li>
                        <li style={privacyPolicyStyle.li}>Microsoft</li>
                        <li style={privacyPolicyStyle.li}>OpenAI</li>
                    </ul>
                </p>

                <h1 style={privacyPolicyStyle.heading}>Final Notes</h1>
                <h2 style={privacyPolicyStyle.subHeading}>Changes to This Privacy Policy</h2>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, following their posting on this page. Check our privacy policy periodically for updates.</p>

                <h2 style={privacyPolicyStyle.subHeading}>Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us at support@sportofix.com.</p>

                <h2 style={privacyPolicyStyle.subHeading}>Effective Date</h2>
                <p>This policy is effective as of 01.10.2023.</p>

            </div>

        </>
    )
}