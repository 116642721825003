import React, { useEffect } from 'react'
import Header from '../../components/Header/index';
import Footer from '../../components/Footer/index';
import Modal from '../../components/Modal/index';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { USER_SUCCESS_CLEAR, USER_ERROR_CLEAR } from "../../Store/types/userType"

export default function Home() {

  const { error, successMessage } = useSelector((state) => state.users)
  const dispatch = useDispatch()

  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const siteurl = protocol + '//' + hostname;

  useEffect(() => {
    if (successMessage) {
      Swal.fire({
        // position: 'top-end',
        icon: 'success',
        title: successMessage,
        showConfirmButton: false,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        timer: 2500
      }).then(() => {
        dispatch({ type: USER_SUCCESS_CLEAR })
        // navigate('/dashboard/default');
      })
    }
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        text: error
      }, 200).then(() => {
        dispatch({ type: USER_ERROR_CLEAR })
      })
    }
  }, [error, successMessage])

  return (
    <>
      <Header url={siteurl} />

      <section className="container-fluid about-section" id="about">
        <div className="row">
          <div className="col-md-7 info order-md-1 order-sm-2 order-2">
            <span>About This App</span>
            <h3 className="mt-3">Do it your way with<br />our <span className="blue">innovative</span> solution</h3>

            <div className="one mt-5">
              <img alt="" src="./images/icon-customizable.svg" />
              <h4>Easy to Use</h4>
              <p>Note observations in real-time and fast-track your reporting with this intuitive app. Simple to use – the same way you use a mobile.</p>
            </div>
            <div className="two mt-5">
              <img alt="" src="./images/icon-easy-to-use.svg" />
              <h4>Standout Professionally</h4>
              <p>AI- powered features help to present your on-field expertise through smart professional reports.</p>
            </div>
            <div className="three mt-5">
              <img alt="" src="./images/icon-generate-reports.svg" />
              <h4>Customizable</h4>
              <p>Use our in-house templates, modify them or create your own. You can change assessment parameters, white – label in your brand style and much more.</p>
            </div>
          </div>
          <div className="col-md-5 image order-md-2 order-sm-1 order-1">
            <img alt="" src="./images/app-screen.png" />
          </div>
        </div>
      </section>

      <section className="container-fluid howitworks-section" id="how-it-works">
        <div className="row">
          <div className="col-sm-12 info text-center">
            <span>How It Works</span>
            <h3 className="mt-3">
              Working with<br />Sportojet is <span className="blue">easy.</span>
            </h3>
            <p className="mt-3">It is a very simple & user-friendly app, accessible anytime, anywhere.</p>
          </div>
        </div>
        <div className="row process mt-5">
          <div className="col-md-4">
            <img alt="" src="./images/icon-add-report.png" />
            <h4 className="mt-3">Instant Start</h4>
            <p className="mt-3">Begin with ease. Capture player performance with a few clicks or voice recordings. SportoJet simplifies every step.</p>
          </div>
          <div className="col-md-4">
            <img alt="" src="./images/icon-analyze-update.png" />
            <h4 className="mt-3">Top-notch Reports</h4>
            <p className="mt-3">Come up with polished, professional reports that impress. Get AI assistance. Share reports in various ways.</p>
          </div>
          <div className="col-md-4">
            <img alt="" src="./images/icon-submit-report.png" />
            <h4 className="mt-3">Always Accessible</h4>
            <p className="mt-3">Watch the players live or on video, SportoJet App is available anytime, anywhere, right from your phone or laptop.</p>
          </div>
        </div>
      </section>

      <section className="container-fluid banner-section" id="try-beta">
        <div className="row">
          <div className="col-sm-9">
            <h3 className="mt-3">Ready for Quick & Powerful Player Reports</h3>
            <p className="mt-3">Let us help you with the boring part – making player reports. You focus on the exciting part – develop great players!</p>
            <button
              className="btn btn-outline-success join-beta-blue mt-3"
              type="button" data-bs-toggle="modal" data-bs-target="#joinModal"
            >
              Try Beta App <i className="fa-solid fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </section>

      <section className="container-fluid features-section" id="features">
        <div className="row">
          <div className="col-sm-12 info text-center">
            <span>Our Features</span>
            <h3 className="mt-3">
              Benefits we offer<br />to our <span className="blue">Coaches</span> &
              <span className="blue">Scouts</span>
            </h3>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4 text-center order-md-1 order-sm-2 order-2 features">
            <div className="accordion" id="accordionExample1">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Save Time & Efforts
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">Save lots of time by automating report preparation. Free up more hours for your other tasks.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Refer Past Reports Easily
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">Access your past player reports easily via SportoJet intuitive interface anytime, anywhere.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Compare Players Quickly
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">Match up and analyze players in different ways to get better insights.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 text-center order-md-2 order-sm-1 order-1">
            <img alt="" src="./images/feature-image.png" />
          </div>
          <div className="col-md-4 text-center order-md-3 order-sm-3 order-3 features">
            <div className="accordion" id="accordionExample2">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    Efficient Feedback
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">Share timely and essential player performance feedback.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Track Players
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">Track player progress over time with the click of a button.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Present Stunning Reports
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">Impress with sleek, expert reports for all audiences – internal or external.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid download-section" id="download">
        <div className="row mt-5">
          <div className="col-md-6 image"></div>
          <div className="col-md-6 info">
            <div>
              <h3 className="mt-5">Ready to Supercharge Your Player Assessments</h3>
              <p className="mt-3">Get exclusive early access to SportoJet and present fantastic reports easily. Save time and efforts, Share great feedback!</p>
              <button
                className="btn btn-outline-success join-beta-blue mt-5"
                type="button" data-bs-toggle="modal" data-bs-target="#joinModal"
              >
                Join Beta
              </button>
              <div className="download-icons mt-5">
                <img alt="" src="./images/android-store.png" />
                <img alt="" src="./images/ios-store.png" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer url={siteurl} />
      <Modal />
    </>
  )
}
