import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { GetPlayerReportByRId } from "../../Store/action/reportsAction"
import { SyncLoader } from "react-spinners";
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import { CLIENT_URL } from "../../Config/keys";

import { GetBrand } from "../../Store/action/brandsAction";

export default function PreviewReport() {
  let dispatch = useDispatch();

  let { id } = useParams();
  console.log(id);

  useEffect(() => {
    dispatch(GetBrand({ adminId: id })); /* Brand Get */
  }, [id]);

  const { error, successMessage } = useSelector((state) => state.users);
  const { reportData, reportError, reportSuccessMessage } = useSelector(
    (state) => state.report
  );
  const { brandData } = useSelector((state) => state.brand);
  console.log(brandData);

  const [Ploader, setPloader] = useState(true);

  const reportTemplateRef = useRef(null);

  // hex color to RGB color
  function hexToRgb(hex) {
    // Remove the # symbol if it's included
    hex = hex.replace(/^#/, "");

    // Parse the hex values into separate R, G, and B values
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b},0.5)`;
  }

  let conditionalStyle = "";
  if (brandData && Object.keys(brandData).length > 0) {
    conditionalStyle = {
      background: `linear-gradient(${hexToRgb(
        brandData.footerBgColor
      )}, ${hexToRgb(
        brandData.footerBgColor
      )}), url(../images/footer-bg-transparent.png)`,
    };
  }

  return (
    <>
      {Ploader && brandData ? (
        <div ref={reportTemplateRef} className="report-body">
          {/* <!-- white labelled header --> */}
          <header className="container-fluid white-labelled">
            <div className="row">
              <div className="col-sm-10">
                <a className="navbar-brand" href="#">
                  <img
                    className="logo"
                    src={
                      brandData.logo || "https://placehold.co/191x84?text=LOGO"
                    }
                  />
                </a>
              </div>
              <div className="col-sm-2 text-end report-header-text">
                <p
                  className="text-center text-md-end"
                >
                  {brandData.headerText || ""}
                  <br />
                </p>
              </div>
            </div>
          </header>
          <header className="container-fluid header-bottom">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="text-start" style={{ color: brandData.titleColor }}>Scouting Report</h1>
              </div>
            </div>
          </header>

          <section className="container-fluid report-content">
            <div className="row player-section mb-4">
              <div className="col-md-3">
                Player Name: <strong>Monaj Dey</strong>
              </div>
              <div className="col-md-3">
                Club / Team: <strong>Santoshpur FC</strong>
              </div>
              <div className="col-md-3">
                Squad: <strong>11V Squad</strong>
              </div>
              <div className="col-md-3">
                Position: <strong>Mid-Field</strong>
              </div>
              <div className="col-md-3">
                Coach: <strong>John Casey</strong>
              </div>
              <div className="col-md-3">
                Report period: <strong>23.Feb.2023</strong>
              </div>
            </div>
            {/* Match Sec */}
            <div className="row match-section">
              <h2 className="section-heading">Match Details</h2>

              <div className="row match-section mb-4">
                <div className="col-md-3">
                  Date & Time: <strong>02-09-2023 5:30 pm</strong>
                </div>
                <div className="col-md-3">
                  Location: <strong>Kolkata</strong>
                </div>
                <div className="col-md-3">
                  Event: <strong>ISL</strong>
                </div>

                <div className="col-md-3">
                  Weather: <strong>Cloudy</strong>
                </div>

                <div className="col-md-3">
                  Home Team Formation: <strong>Formation 1</strong>
                </div>
                <div className="col-md-3">
                  Away Team Formation: <strong>Formation 2</strong>
                </div>
                <div className="col-md-3">
                  Format: <strong>V1 format</strong>
                </div>
                <div className="col-md-3">
                  Match Score Half-Time: <strong>05 - 02</strong>
                </div>
                <div className="col-md-3">
                  Match Score Full-Time: <strong>00 - 02</strong>
                </div>
                <div className="col-md-3">
                  Venue: <strong>Saltlake,Kolkata</strong>
                </div>
                <div className="col-md-3">
                  Age Group: <strong>U-10</strong>
                </div>
                <div className="col-md-3">
                  Compitition LVL: <strong>Hard</strong>
                </div>
                <div className="col-md-3">
                  Viewing: <strong>Video</strong>
                </div>
                <div className="col-md-3">
                  Goal Scored: <strong>3</strong>
                </div>
                <div className="col-md-3">
                  Cards: <strong>Red</strong>
                </div>
                <div className="col-md-3">
                  Substitute: <strong>ABC</strong>
                </div>
              </div>
            </div>

            {/* Graph Sec */}
            <div className="row graph-section mb-4">
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <h2 className="section-heading green">Overall Score</h2>
                    {/* <div className="overall-score" style={{ backgroundColor: brandData.buttonColor }}>9</div> */}
                    <div className="overall-score-one">
                      <div className="number-overall-score-two" style={{ backgroundColor: brandData.buttonColor }}>
                        <span>7.6</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9">
                    <h3 className="section-heading">General comment</h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>

                <div className="row bars mt-5">
                  <h3 className="category">Technical</h3>
                  <div className="col-sm-1">
                    <div
                      className="score"
                      style={{ backgroundColor: brandData.buttonColor }}
                    >
                      5.3
                    </div>
                    <div
                      className="score"
                      style={{ backgroundColor: brandData.buttonColor }}
                    >
                      5.3
                    </div>
                    <div
                      className="score"
                      style={{ backgroundColor: brandData.buttonColor }}
                    >
                      5.3
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "10%",
                          backgroundColor: brandData.progressBarColor,
                        }}
                        aria-valuenow="10"
                        aria-valuemin="1"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "25%",
                          backgroundColor: brandData.progressBarColor,
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "50%",
                          backgroundColor: brandData.progressBarColor,
                        }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="parameter">
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: brandData.progressBarColor }}
                      ></i>{" "}
                      Passing and Retention
                    </div>
                    <div className="parameter">
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: brandData.progressBarColor }}
                      ></i>{" "}
                      First touch and Receiving
                    </div>
                    <div className="parameter">
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: brandData.progressBarColor }}
                      ></i>{" "}
                      Tackling
                    </div>
                  </div>
                </div>

                <div className="row bars mt-5">
                  <h3 className="category">Tactical</h3>
                  <div className="col-sm-1">
                    <div
                      className="score"
                      style={{ backgroundColor: brandData.buttonColor }}
                    >
                      5.3
                    </div>
                    <div
                      className="score"
                      style={{ backgroundColor: brandData.buttonColor }}
                    >
                      5.3
                    </div>
                    <div
                      className="score"
                      style={{ backgroundColor: brandData.buttonColor }}
                    >
                      5.3
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "10%",
                          backgroundColor: brandData.progressBarColor,
                        }}
                        aria-valuenow="10"
                        aria-valuemin="1"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "25%",
                          backgroundColor: brandData.progressBarColor,
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "50%",
                          backgroundColor: brandData.progressBarColor,
                        }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="parameter">
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: brandData.progressBarColor }}
                      ></i>{" "}
                      1 v 1 Situations
                    </div>
                    <div className="parameter">
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: brandData.progressBarColor }}
                      ></i>{" "}
                      Defending
                    </div>
                    <div className="parameter">
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: brandData.progressBarColor }}
                      ></i>{" "}
                      Game Intelligence
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Analitics sec */}
            <div className="row analysis-section mb-4">
              <div className="container">
                <div className="row category mb-4">
                  <div className="col-md-12">
                    <h2
                      className="section-heading"
                      style={{ color: brandData.progressBarColor }}
                    >
                      Technical
                    </h2>
                  </div>

                  <div className="col-sm-3 parameter">
                    <h3
                      className="mt-3"
                      style={{ color: brandData.progressBarColor }}
                    >
                      Passing & Retention
                    </h3>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "10%",
                          backgroundColor: brandData.progressBarColor,
                        }}
                        aria-valuenow="10"
                        aria-valuemin="1"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-sm-8 comment">
                    <p className="mt-3">
                      Good reaction, good accuracy. Left leg kick needs to be
                      improved.
                    </p>
                  </div>
                  <div className="col-sm-1 text-end">
                    <div
                      className="score"
                      style={{ background: brandData.buttonColor }}
                    >
                      5.3
                    </div>
                  </div>

                  <div className="col-sm-3 parameter">
                    <h3
                      className="mt-3"
                      style={{ color: brandData.progressBarColor }}
                    >
                      First Touch & Receiving
                    </h3>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "10%",
                          backgroundColor: brandData.progressBarColor,
                        }}
                        aria-valuenow="10"
                        aria-valuemin="1"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-sm-8 comment">
                    <p className="mt-3">
                      Good reaction, good accuracy. Left leg kick needs to be
                      improved.
                    </p>
                  </div>
                  <div className="col-sm-1 text-end">
                    <div
                      className="score"
                      style={{ background: brandData.buttonColor }}
                    >
                      5.3
                    </div>
                  </div>
                </div>

                <div className="row category mb-4">
                  <div className="col-md-12">
                    <h2
                      className="section-heading"
                      style={{ color: brandData.progressBarColor }}
                    >
                      Tactical
                    </h2>
                  </div>

                  <div className="col-sm-3 parameter">
                    <h3
                      className="mt-3"
                      style={{ color: brandData.progressBarColor }}
                    >
                      1 v 1 Situations
                    </h3>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "10%",
                          backgroundColor: brandData.progressBarColor,
                        }}
                        aria-valuenow="10"
                        aria-valuemin="1"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-sm-8 comment">
                    <p className="mt-3">
                      Good reaction, good accuracy. Left leg kick needs to be
                      improved.
                    </p>
                  </div>
                  <div className="col-sm-1 text-end">
                    <div
                      className="score"
                      style={{ background: brandData.buttonColor }}
                    >
                      5.3
                    </div>
                  </div>

                  <div className="col-sm-3 parameter">
                    <h3
                      className="mt-3"
                      style={{ color: brandData.progressBarColor }}
                    >
                      Defending
                    </h3>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "10%",
                          backgroundColor: brandData.progressBarColor,
                        }}
                        aria-valuenow="10"
                        aria-valuemin="1"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-sm-8 comment">
                    <p className="mt-3">
                      Good reaction, good accuracy. Left leg kick needs to be
                      improved.
                    </p>
                  </div>
                  <div className="col-sm-1 text-end">
                    <div
                      className="score"
                      style={{ background: brandData.buttonColor }}
                    >
                      5.3
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Video Section */}
            <div className="row videos-section mb-4">
              <h2 className="section-heading mt-4">Uploaded Videos</h2>
              <div className="container">
                <div className="row category mb-4">
                  <div className="col-md-2 thumbnail">
                    {/* <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <img src="https://placehold.co/98x82" />
                    </a> */}
                        <video className="video66" controls preload="auto">
                      <source
                        src="https://sportojet1234.blob.core.windows.net/sportojet/1688905649573-production_id_3814951%20(1080p).mp4"
                      />
                      <source
                        src="https://sportojet1234.blob.core.windows.net/sportojet/1688905649573-production_id_3814951%20(1080p).mp4"
                      />
                    </video>
                  </div>

                  <div className="col-md-3 name p-2">
                    <h3>
                      FC Stars - Inter
                    </h3>
                  </div>

                  <div className="col-md-5 comment p-2">
                    Small note to know what the clip is about for ease of use.
                  </div>

                  <div className="col-md-2 date p-2">26/04/2023</div>
                </div>

                <div className="row category mb-4">
                  <div className="col-md-2 thumbnail">
                    {/* <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <img src="https://placehold.co/98x82" />
                    </a> */}
                    <video className="video66" controls preload="auto">
                      <source
                        src="https://sportojet1234.blob.core.windows.net/sportojet/1688905649573-production_id_3814951%20(1080p).mp4"
                      />
                      <source
                        src="https://sportojet1234.blob.core.windows.net/sportojet/1688905649573-production_id_3814951%20(1080p).mp4"
                      />
                    </video>
                  </div>

                  <div className="col-md-3 name p-2">
                    <h3 >
                      FC Stars - Inter
                    </h3>
                  </div>

                  <div className="col-md-5 comment p-2">
                    Small note to know what the clip is about for ease of use.
                  </div>

                  <div className="col-md-2 date p-2">26/04/2023</div>
                </div>
              </div>
            </div>
          </section>

          <section className="container print-options">
            <div className="row">
              <div className="col-md-12 text-center">
                <button
                  type="button"
                  className="btn btn-dark"
                  style={{ backgroundColor: brandData.buttonColor }}
                >
                  <i className="fa-solid fa-download"></i> Download
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  style={{ backgroundColor: brandData.buttonColor }}
                >
                  <i className="fa-solid fa-print"></i> Print
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  style={{ backgroundColor: brandData.buttonColor }}
                >
                  <i className="fa-solid fa-share-square"></i> Share
                </button>
              </div>
            </div>
          </section>

          {/* <footer className="mt-auto mt-5">
            <div className="row">
              <div className="col-sm-12 text-center">
                <a className="navbar-brand" href="#">
                  <img className="logo" src={`${CLIENT_URL}/images/logo.png`} />
                </a>
                <p className="mt-3">
                  &copy;2023 All Rights Reserved. SportoJet Inc.
                  <br />
                  support@sportojet.com
                </p>
              </div>
            </div>
          </footer> */}

          {/* <!-- white labelled footer --> */}
          <footer
            className="mt-auto white-labelled mt-5"
            style={conditionalStyle || null}
          >
            <div className="row">
              <div className="col-sm-12 text-center">
                <a className="navbar-brand" href="#">
                  <img
                    className="logo"
                    src={
                      brandData.logo || "https://placehold.co/191x84?text=LOGO"
                    }
                  />
                </a>
                <p className="mt-3">{brandData.footerText}</p>
              </div>
            </div>
          </footer>

          <footer className="mt-auto footer-bottom mt-5">
            <div className="row">
              <div className="col-sm-12 text-center">
                <p className="mt-3">Powered by Sportojet</p>
              </div>
            </div>
          </footer>
        </div>
      ) : (
        <div className="page-loader">
          {/* <SyncLoader color="#36d7b7" /> */}
        </div>
      )}
    </>
  );
}
