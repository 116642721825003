// import logo from './logo.svg';
import './App.css';
import Home from './pages/Home/index';
import ScoutingReport from './pages/ScoutingReport/index';
import PreviewReport from './pages/PreviewReport';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';

// third party style
// import "perfect-scrollbar/css/perfect-scrollbar.css";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<Home />} ></Route>
          <Route path='/report/:id' exact element={<ScoutingReport />} ></Route>
          <Route path='/preview-report/:id' exact element={<PreviewReport />} ></Route>
          <Route path='/privacy-policy' exact element={<PrivacyPolicy />} ></Route>
          <Route path='*' exact element={<Home />} ></Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
