import { SERVER_URI } from "../../Config/keys";
import axios from 'axios';


import { FINISHED_REPORT_GET_BYPID_SUCCESS, 
    FINISHED_REPORT_GET_BYPID_ERROR,
    REPORT_DATA_COLLECTION_SUCCESS,
    REPORT_DATA_COLLECTION_ERROR
} from "../../Store/types/reportsType"


export const GetPlayerReportByRId = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                reportid: data.reportId,
            }
        }
        try {
            const response = await axios.get(`${SERVER_URI}/reports/get-all-finished-reports-for-client-page`, config);
            // console.log(106,response.data.data);
            dispatch({
                type: FINISHED_REPORT_GET_BYPID_SUCCESS,
                payload: {
                    reportData: response.data.data,
                },
            });

        } catch (error) {
            let data = error.response.data.message;
            // console.log(data);
            dispatch({
                type: FINISHED_REPORT_GET_BYPID_ERROR,
                payload: {
                    reportError: data,
                },
            });
        }
    };
};

export const saveReportAnalitics = (data) => {
    return async (dispatch) => {
        // const config = {
        //     headers: {
        //         'Content-Type': 'application/josn',
        //         reportid: data.reportId,
        //     }
        // }
        console.log(data);

        try {
            const response = await axios.post(`${SERVER_URI}/reports/save-report-analitics`, data);

            dispatch({
                type: REPORT_DATA_COLLECTION_SUCCESS,
                payload: {
                    reportData: response.data.data,
                },
            });

        } catch (error) {
            let data = error.response.data.message;

            dispatch({
                type: REPORT_DATA_COLLECTION_ERROR,
                payload: {
                    reportError: data,
                },
            });
        }
    }
}


